<template>
  <el-main class="simple3-container">
    <section class="inner-container">
      <div class="section-h2">
        <MainContain />
        企業身心健康計畫
      </div>
      <p>2023年2月 </p>
      <div class="image-container">
        <img :src="image" class="image" alt="企業身心健康計畫">
        <p class="image_desc">健康職場 I can do it｜打造自我薪動力</p>
        <p class="image_desc">圖片為中強光電員工三分鐘登階測驗(前後側)</p>
        <p class="image_desc">(圖片提供：本計畫)</p>
      </div>
      <div class="content-section">
        <div class="section-h3">公益目標</div>
        <div class="content-desc">
          根據110年教育部體育署針對台灣運動現況的調查結果顯示：110年企業職工規律運動相較於109年，白領增加1.1%達25.2%，藍領增加2.3%，達21.5%。
        </div>
        <div class="content-desc">
          數據顯示企業職工規律運動增加，並在強調企業落實企業社會責任的風氣之下，企業更加注重員工的身心健康、打造健康職場，推動更多創新與數據利他價值應用。
        </div>
      </div>
      <div class="content-section">
        <div class="section-h3">數據貢獻活動 </div>
        <div class="content-desc">
          中強光電引進宇康醫電uGym智慧健身系統，針對代謝症候群員工執行「I Health Plan」於民國111年10月11日至12月31日，共完成累計977人次及247,760筆運動生理數據，其中包含心率、時速、性別、年齡、迴轉數等運動數據，完整數據留存uGym數位健身系統，並已與運動數據公益平台串接。
        </div>
      </div>
      <div class="content-section">
        <div class="section-h3">數據公益模式</div>
        <div class="content-desc">
          透過宇康醫電體適能評估及數位健身系統(uGym)將所收集到的數據建立成模型，將推論的結果個人化，系統可以貼合使用者的需求，成為後續運動處方箋之依據，並同時促成全民健康。
        </div>
      </div>
      <div class="content-section">
        <div class="section-h3">公益展望</div>
        <div class="content-desc">
          針對代謝症候群提供企業員工短期高效智慧運動應用模式，透過體適能前後測，設定不同運動模式之處方。監測運動過程心率與處方強度，比對確認運動效果（體脂率、血壓、休息心率等等）輔以運動關懷服務，推動企業員工健康促進。 
        </div>
      </div>
    </section>
  </el-main>
</template>

<script>
import MainContain from '@/components/Accessibility/MainContain.vue'

export default {
  name: 'UseCase11103',
  components: {
    MainContain
  },
  data () {
    return {
      image: require('@/assets/images/sample/sample_3.png')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/index.scss";
.simple3-container{
  .inner-container{
    max-width: 800px;
    margin: 0 auto;
    padding: 1.25rem 0;
  }
  .image-container{
    padding: 1.5rem 0;
    .image {
      width: 80%;
      display: block;
      margin: 0 auto;
      }
    .image_desc{
      text-align: center;
    }
  }
  
  .content-section{
    
    .content-desc{
      line-height: 1.6;
    }
  }
}

</style>
